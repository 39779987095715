<template>
  <form-reallocations />
</template>

<script>
  import FormReallocations from '@/components/forms/form-reallocations'

  export default {
    name: 'edit',
    components: { FormReallocations },

    beforeRouteLeave (to, from, next) {
      next(true)
    },
  }
</script>

<style scoped>

</style>
